.text-list-section {
    .background-primary & {
        * {
            color:#fff;
        }
    }
    .c-richtext {
    
        p {
            font-size:24px;
            line-height:1.33 !important;
        }
        @media (max-width: $lg) {
            font-size:18px;
            * {
                font-size:18px;
            }
        }
    
    }
    .list {
        padding:0 10%;
        @media (max-width: $lg) {
            padding:0 14px;
            margin-top:40px;
        }
        ol {
            margin:0;
            padding:0;
            list-style:none;
            li {
                counter-increment: item;
                position: relative;
                min-height:56px;
                padding-left:96px;
                margin-bottom:32px;
                padding-bottom:32px;
                border-bottom:1px solid #fff;
                padding-top:5px;
                font-size:24px;
                line-height:1.3;
                &:last-child {
                    margin-bottom:0;
                }
                &:last-child {
                    border-bottom:0;
                }
                span.no {
                    position: absolute;
                    top:-5px;
                    left:0;
                    font-size: 48px;
                    font-weight:700;
                    line-height:1;
                }
                @media (max-width: $lg) {
                    padding-left:64px;
                    min-height:40px;
                    margin-bottom:24px;
                    padding-bottom:24px;
                    font-size:18px;
                    span.no {
                        top:0;
                        font-size:34px;
                    }
                }
            }
        }
    }

}