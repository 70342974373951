.c-section {
    @media (max-width: 950px) {
        padding:0 !important;
    }
}

.c-section-nav {
    background-color: #000;
    position: sticky;
    top:0;
    left:0;
    z-index:1500;
    &:before {
        content:'';
        position: absolute;
        width: 100vw;
        max-width:1920px;
        height: calc(100% + 1px);
        z-index: 0;
        left:50%;
        transform:translateX(-50%);
        background-color: #000;
        border-bottom:1px solid #fff;
    }

    &__wrap {
        display:flex;
        position: relative;
        z-index:10;
        @media (max-width: 650px) {
            justify-content: space-between;
        }
    }
    &__item {
        padding:32px 24px;
        align-items: center;
        display:flex;
        cursor:pointer;
        
        &-icon {
            display:inline-block;
            width: 40px;
            flex-basis:40px;
            img {
                width: 100%;
            }
        }
        &-name {
            padding-left:16px;
            color:#fff;
        }
        @media (max-width: 950px) {
            padding:24px 16px;
            &-icon {
                width: 24px;
                flex-basis:24px;
            }
            &-name {
                padding-left:8px;
            }
        }
        @media (max-width: 650px) {
            justify-content: center;
            display:block;
            padding:16px 16px;
            &-icon {
                width: 24px;
                flex-basis:24px;
                display:block;
                margin:0 auto 4px auto;
            }
            &-name {
                padding-left:0;
                text-align:center;
                font-size:12px !important;
            }
        }
        &.active,
        &:hover {
            background-color: #fff;
            .c-section-nav__item-name {
                color:#000;
            }
        }
    }
}