.three-columns-section {
    h3 {
        margin-bottom:80px;
        @media (max-width: $lg) {
            margin-bottom:40px;
        }
    }

    .grid {
        display:grid;
        column-gap:48px;
        row-gap:48px;
        grid-template-columns: 1fr 1fr 1fr;
        @media (max-width: $lg) {
            column-gap:24px;
            row-gap:32px;
            grid-template-columns: 1fr;
        }
        @media (max-width: $md) {
            column-gap:24px;
            row-gap:32px;
            grid-template-columns: 1fr;
        }
    }

}