.media-cont-section {
    position: relative;
    .row {
        min-height:480px;
    }
    @media (max-width: $lg) {
        padding-bottom:0;
        .row {
            min-height:200px;
        }
    }
    .image {
        > .c-cover-video {
            position: absolute;
            right:50%;
            top:0;
            height:100%;
            width:50%;
            padding-bottom:0;
        }
        > .c-image {
            position: absolute;
            right:50%;
            top:0;
            height:100%;
            width:50%;
            img {
                object-fit: cover;
                position: absolute;
                top:0;
                left:0;
                bottom:0;
                right:0;
                max-width: none;
                height:100%;
                width: 100%;;
            }
        }
        @media (max-width: $lg) {
            padding:0;
            > .c-cover-video,
            > .c-image {
                position: relative;
                right:0;
                height:auto;
                width:100%;
                margin-top:48px;
                padding:0;
                img {
                    width:100%;
                    position: relative;
                    height:auto;
                    transform:none;
                    top:0;
                    left:0;
                }
            }
            .c-cover-video {
                padding-bottom:56.25%;
            }
        }
    }
    .content {
        padding-left:10%;
        padding-right:2%;
        @media (max-width: $xlc) {
            padding-left:5%;
            padding-right:14px;
        }
        @media (max-width: $lg) {
            padding-left:14px;
            padding-right:14px;
        }
    }
}