.c-tab {
    position: relative;
    &__nav {
        @media (max-width: 575px) {
            overflow-x:auto;
            width:calc(100% + 16px);
            position: relative;
           
        }
        &-wrap {
            width:100%;
            display:flex;
            cursor:pointer;
            @media (max-width: 575px) {
                width:680px;
                flex-basis:680px;
            }
        }
        &-item {
            text-decoration:none;
            display:block;
            padding:32px;
            color:#fff;
            border-bottom:1px solid #fff;
            position: relative;
            flex:auto;
            text-align:center;
            cursor:pointer;
            @media (max-width: 950px) {
                padding:16px 0;
            }
            &:after {
                content: '';
                position: absolute;
                width: 0%;
                height: 4px;
                background-color: #fff;
                bottom:0;
                left:0;
                transition:all .3s ease;
            }
            &:hover {
                color:#fff;
            }
            &.active {
                color:#fff;
                &:after {
                    width:100%;
                }
            }
        }
    }
    &__panel {
        padding:48px 0 96px 0;
        display:none;
        &.active {
            display:block;
        }
        @media (max-width: 1150px) {
            padding:32px 0 64px 0;
        }
        @media (max-width: 950px) {
            padding:24px 0 32px 0;
            .row {
                > div:first-child {
                    margin-bottom:24px;
                }
            }
        }
        &-content {
            * {
                color:#fff;
            }
        }
        &-buttons {
            margin-top:32px;
            display:flex;
            column-gap: 8px;
            row-gap:8px;
            @media (max-width: 950px) {
                margin-top:16px;
            }
            .btn-light-container {
                
                .btn-text {
                    color:#000;
                }
                .btn-arrow {
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 16px;
                    background-image: url(/assets/images/right_arrow.svg);
                }
                &:hover {
                    .btn-text {
                        color:#fff;
                    }
                    .btn-arrow {
                        background-image: url(/assets/images/right_arrow_white.svg);
                    }
                }
            }
        }
    }
}