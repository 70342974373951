.cta-section {
    text-align:center;
    .cta-button {
        display:flex;
        justify-content: center;
        margin-top:40px;
        @media (max-width: $lg) {
            margin-top:24px;
        }
    }   
    .c-richtext {
        p {
            margin-bottom:40px;
            @media (max-width: $lg) {
                margin-bottom:24px;
            }
        }
        &.footnote {
            margin-top:40px;
            max-width:80%;
            margin-left:auto;
            margin-right:auto;
            @media (max-width: $lg) {
                max-width:100%;
                margin-top:24px;
            }
        }
    }
}