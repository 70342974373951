.lg-img-cont-section {

    .left {
        .content {
            padding-left:5%;
            @media (max-width: $lg) {
                padding-left:14px;
            }
        }
    }
    .right {
        .content {
            padding-right:5%;
            @media (max-width: $lg) {
                padding-right:14px;
            }
        }
    }

    @media (max-width: $lg) {
        .image {
            margin-bottom:32px;
        }
    }


    .button {
        margin-top:24px;
    }
}