.diaries-section {
    h2 {
        text-align:center;
        margin-bottom:80px;
        @media (max-width: $lg) {
            margin-bottom:40px;
        }
    }

    .flex {
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
    }

    .left {
        width: calc(50% - 20px);
        flex-basis: calc(50% - 20px);
        display:grid;
        row-gap:40px;
        grid-template-columns: 1fr;
        @media (max-width: $lg) {
            width: 100%;
            flex-basis: 100%;
            row-gap:16px;
            order:2
        }
    }
    .right {
        width: calc(50% - 20px);
        flex-basis: calc(50% - 20px);
        display:flex;
        .c-diary {
            display:flex;
            flex-wrap:wrap;
            align-content: flex-start;
        }
        @media (max-width: $lg) {
            width: 100%;
            flex-basis: 100%;
            row-gap:24px;
            order:1;
            margin-bottom:16px;
        }
    }

    #js-diaries-result {
        display:none;
    }

    .grid {
        margin-top:40px;
        display:grid;
        grid-template-columns: 1fr 1fr;
        row-gap:40px;
        column-gap:40px;
        width:100% !important;
        @media (max-width: $lg) {
            width: 100%;
            flex-basis: 100%;
            row-gap:16px;
            margin-top:16px;
            grid-template-columns: 1fr;
        }
    }

    .load-more {
        margin-top:80px;
        text-align:center;
        @media (max-width: $lg) {
            margin-top:40px;
        }
        a {
            margin:0 auto;
            &.disabled {
                cursor:not-allowed;
                pointer-events: none;
            }
            &.arrow-down {
                .btn-arrow {
                    background-image:url();
                    &:after {
                        content: '';
                        position: relative;
                        width:16px;
                        height:16px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 16px;
                        background-image: url(/assets/images/right_arrow.svg);
                        transform:rotate(90deg);
                    }
                }
                &:hover {
                    .btn-arrow {
                        background-image:url();
                        &:after {
                            background-image: url(/assets/images/right_arrow_white.svg);
                        }
                    }
                }
            }
        }
    }
}