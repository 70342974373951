.c-image {
    img {
        max-width:100%;
    }
}

.c-image-cover {
    position: relative;
    height:0;
    width: 100%;
    overflow:hidden;
    img {
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        object-fit: cover;
        height:100%;
        width: 100%;
        transition:all .3s ease;
    }
}