.c-accordion {
    margin:0;
    padding:0;
    list-style:none;
    display:block;

    @media (max-width: 950px) {
        padding:0 16px
    }
    
    li {
        position: relative;
        &:before {
            content:'';
            position: absolute;
            left:50%;
            top:0;
            transform:translateX(-50%);
            width: 100vw;
            max-width:1920px;
            height: 1px;
            background-color: #fff;
            z-index: 100;
        }
        &.active {
            .c-accordion__content{
                display:block;
            }
        }
    }

    &__header {
        padding:64px 0;
        display:flex;
        flex-wrap:wrap;
        cursor:pointer;
        justify-content: space-between;
        @media (max-width: 950px) {
            padding:48px 0;
        }
        @media (max-width: 950px) {
            padding:24px 0;
        }
        &-title {
            display:flex;
            align-items: center;
            width:25%;
            flex-basis:25%;
            @media (max-width: 950px) {
                width:100%;
                flex-basis:100%;
                margin-bottom:16px;
            }
        }
        &-icon {
            width:80px;
            flex-basis:80px;
            img {
                max-width:100%;
            }
            @media (max-width: 950px) {
                width:40px;
                flex-basis:40px;
            }
            @media (max-width: 650px) {
                width:30px;
                flex-basis:30px;
            }
        }
        &-name {
            font-size:48px;
            color:#fff;
            font-weight:200;
            margin-left:32px;
            @media (max-width: 950px) {
                font-size:32px;
                margin-left:24px;
            }
            @media (max-width: 650px) {
                font-size:28px;
                margin-left:16px;
            }
        }
        &-content {
            width:65%;
            flex-basis:65%;
            padding-right:140px;
            position: relative;
            @media (max-width: 950px) {
                width:100%;
                flex-basis:100%;
                padding-right:96px;
            }
            @media (max-width: 650px) {
                padding-right:0;
            }
            &:after {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23FFFFFF' d='M252.485 343.03l-7.07-7.071c-4.686-4.686-12.284-4.686-16.971 0L145 419.887V44c0-6.627-5.373-12-12-12h-10c-6.627 0-12 5.373-12 12v375.887l-83.444-83.928c-4.686-4.686-12.284-4.686-16.971 0l-7.07 7.071c-4.686 4.686-4.686 12.284 0 16.97l116 116.485c4.686 4.686 12.284 4.686 16.971 0l116-116.485c4.686-4.686 4.686-12.284-.001-16.97z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 12px;
                position: absolute;
                top:50%;
                margin-top:-32px; 
                right:16px;
                width: 64px;
                height: 64px;
                border-radius:200px;
                border:1px solid #fff;
                transition:all .3s ease;
                transform:rotate(0);
                .active & {
                    transform:rotate(-180deg);
                }
                @media (max-width: 950px) {
                    width: 48px;
                    height: 48px;
                    top:0;
                    margin-top:0;
                }
                @media (max-width: 650px) {
                    width: 32px;
                    height: 32px;
                    top:-56px;
                    right:0;
                    background-size: 8px;
                }
            }
            p {
                color:#fff;
            }
        }
    }

    &__content {
        display:none;
        h3 {
            color:#fff;
            margin-bottom:32px;
            @media (max-width: 950px) {
                margin-bottom:16px;
            }
        }
    }

}