.img-cont-section {
    .row {
        .col-lg-8 {
            padding-left:100px;
            @media (max-width: $lg) {
                padding-left:14px;
                margin-bottom:16px;
            }
        }
    }
}