.c-cta-items {
    display:flex;
    column-gap:96px;
    row-gap:80px;
    flex-wrap:wrap;
    margin-top:48px;
    @media (max-width: $lg) {
        column-gap:32px;
        row-gap:32px;
        margin-top:32px;
    }
}
.c-cta-item {
    width:auto;
    .c-image {
        img {
            max-width:160px;
            display:block;
            @media (max-width: $lg) {
                max-width:120px;
            }
        }
        figcaption {
            display:block;
            color:#fff !important;
            padding:16px 0;
            @media (max-width: $lg) {
                padding:8px 0;
            }
        }
    }
    a.btn-light-container {
        
    }
}