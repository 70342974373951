.stories-section {
    overflow:hidden;
    
    .thumbswiper {
        margin-top:80px;
        .swiper-slide {
            width: 150px;
            padding:8px;
            &.swiper-slide-active {
                border:1px solid #fff;
                border-radius:8px;
            }
        }
    }

    .desktop {
        display:block;
        @media (max-width: $lg) {
            display:none;
        }
    }
    .mobile {
        display:none;
        @media (max-width: $lg) {
            display:block;
        }
    }

    .mainswiper-mobile {
        width:80%;
        margin:0;
        overflow:visible;
        .swiper-slide-active {
            .c-slide-item {
                &__content {
                    opacity:1;
                }
            }
        }
        .c-slide-item {
            &__content {
                opacity:0;
            }
        }
    }
}