.three-highlight-section {
    h2 {
        text-align:center;
        margin-bottom:80px;
        @media (max-width: $lg) {
            margin-bottom:40px;
        }
    }

    .grid {
        display:grid;
        grid-template-columns: 1fr;
        row-gap:40px;
        @media (max-width: $lg) {
            row-gap:24px;
        }
        @media (max-width: $sm) {
            row-gap:16px;
        }
    }
}