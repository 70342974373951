body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
}

.hero-scroller {
    .svg-image {
        svg {
            max-width:100%;
        }
    }
    .block-content.title {
        max-width:80%;
        margin:0 auto;
    }
}

@media (max-width: $lg) {
    .menu-container {
        overflow: auto !important;
        visibility:hidden;
        opacity:0;
        .menu-open & {
            visibility:visible;
            opacity:1;
        }
    }
}

.section-bg.has-bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.section-padding-top-xlarge {
    padding-top:10rem;
    @media (max-width: $lg) {
        padding-top:3rem;
    }
}
.section-padding-bottom-xlarge {
    padding-bottom:10rem;
    @media (max-width: $lg) {
        padding-bottom:3rem;
    }
}


.btn-light-container.arrow {
    .btn-arrow {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 16px;
        background-image: url(/assets/images/right_arrow.svg);
    }
    &:hover {
        .btn-arrow {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 16px;
            background-image: url(/assets/images/right_arrow_white.svg);
        }   
    }
}

.border-top-white {
    border-top:1px solid white;
}
.border-bottom-white {
    border-bottom:1px solid white;
}

.section-bg.full-width {
    .container-xxl {
        max-width:100%;
        padding:0;
    }
}