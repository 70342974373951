.white-text {
    * {
        color:#ffffff;
    }
}
.section-block .c-richtext {

    h3 {
        font-size:40px;
        line-height:1.33;
        @media (max-width: $lg) {
            font-size:32px;
        }
    }

    &__header {
        margin-bottom:24px;
        h3, h2 {
            margin-bottom:4px !important;
        }
        .subtitle {
            font-size: 24px;
        }
        @media (max-width: $lg) {
            .subtitle {
                font-size: 18px;
            }   
        }
    }

    *:last-child {
        margin-bottom:0;
    }

    .white-text &,
    .background-primary & {
        * {
            color:#fff;
        }
    }

    h1,h2,h3,h4,h5,h6 {
        margin-bottom:32px;
        @media (max-width: $lg) {
            margin-bottom:24px;
        }
    }

    p {
        margin-bottom:32px;
        @media (max-width: $lg) {
            margin-bottom:24px;
        }
        
        &.medium {
            font-size:24px;
            line-height:1.33 !important;
            * {
                font-size:24px;
                line-height:1.33 !important;
            }
            @media (max-width: $lg) {
                font-size:18px;
                * {
                    font-size:18px;
                }
            }
        }

        &.lead {
            font-size:32px;
            line-height:1.25 !important;
            * {
                font-size:32px;
                line-height:1.25 !important;
            }
            @media (max-width: $lg) {
                font-size:22px;
                * {
                    font-size:22px;
                }
            }
        }

        &.xlarge {
            font-size:48px;
            line-height:1.2;
            * {
                font-size:48px;
                line-height:1.2;
            }
            @media (max-width: $lg) {
                font-size:28px;
                * {
                    font-size:28px;
                }
            }
        }

        &.footnote {
            font-size:12px;
        }

        &.cap-subtitle {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            letter-spacing: 1.2px;
            text-transform: uppercase;
        }
    }

    img.aligncenter {
        display:block;
        margin:0 auto;
    }
    img {
        @media (max-width: $sm) {
            max-width:50%;
        }
    }
}