.video-carousel-section,
.swiper-section {
    overflow:hidden;
    .intro-content {
        margin-bottom:80px;
        @media (max-width: $lg) {
            margin-bottom:40px;
        }
    }
    .slider-swiper-navigation {
        position: relative;
        .pageno {
            font-size:12px;
            font-weight:500;
            position: absolute;
            top:50%;
            right:calc(100% + 40px);
            transform:translateY(-50%);
            white-space: nowrap;
            letter-spacing:1.2px;
            @media (max-width: $lg) {
                right:0;
            }
        }
    }
    .swiper {
        overflow:visible;
    }
}