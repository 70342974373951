@font-face {
    font-family: 'Helvetica Now Display Regular';
    src: url('../fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
        url('../fonts/HelveticaNowDisplay-Bold.woff') format('woff');
        font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gochi Hand';
    src: url('../fonts/GochiHand-Regular.woff2') format('woff2'),
        url('../fonts/GochiHand-Regular.woff') format('woff');
        font-weight: normal;
    font-style: normal;
    font-display: swap;
}