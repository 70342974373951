.c-cover-video {
    overflow:hidden;
    position: relative;
    padding-bottom:83.116%;
    video {
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        min-height:100%;
        min-width:100%;
        @media (max-width: $sm) {
            width:150%;
        }
    }
}