.c-video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.c-video {
    position: relative;
    cursor:pointer;
    &.playing {
        .c-video__play {
            opacity:0;
        }
    }
    &__play {
        cursor:pointer;
        position: absolute;
        opacity:1;
        transition:all .3s ease;
        top:50%;
        left:50%;
        width: 80px;
        height: 80px;
        transform:translateX(-50%) translateY(-50%);
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80' fill='none'%3E%3Cg clip-path='url(%23clip0_2007_74)'%3E%3Cpath d='M40 80C29.3156 80 19.2706 75.8394 11.7156 68.2844C4.16063 60.7294 0 50.6844 0 40C0 29.3156 4.16063 19.2708 11.7156 11.7156C19.2706 4.16078 29.3156 0 40 0C50.6844 0 60.7292 4.16078 68.2844 11.7158C75.8394 19.2706 80 29.3156 80 40C80 50.6844 75.8394 60.7294 68.2844 68.2844C60.7292 75.8394 50.6844 80 40 80ZM40 3.4375C19.8394 3.4375 3.4375 19.8394 3.4375 40C3.4375 60.1606 19.8394 76.5625 40 76.5625C60.1606 76.5625 76.5625 60.1606 76.5625 40C76.5625 19.8394 60.1606 3.4375 40 3.4375Z' fill='black'/%3E%3Cpath d='M30.7539 56.4798C30.4659 56.4798 30.1775 56.4074 29.917 56.2623C29.3727 55.9588 29.0352 55.3843 29.0352 54.761V25.5635C29.0352 24.939 29.3739 24.3635 29.9202 24.0605C30.4663 23.7576 31.1337 23.7746 31.6636 24.1052L55.1734 38.7704C55.6778 39.0849 55.9837 39.6377 55.9825 40.2323C55.9812 40.8266 55.6731 41.3782 55.1675 41.6907L31.6577 56.223C31.3811 56.394 31.0677 56.4798 30.7539 56.4798Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2007_74'%3E%3Crect width='80' height='80' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        &:hover {
            opacity:0.5;
        }
    }
    &--white {
        .c-video__play {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80' fill='none'%3E%3Cg clip-path='url(%23clip0_2007_74)'%3E%3Cpath d='M40 80C29.3156 80 19.2706 75.8394 11.7156 68.2844C4.16063 60.7294 0 50.6844 0 40C0 29.3156 4.16063 19.2708 11.7156 11.7156C19.2706 4.16078 29.3156 0 40 0C50.6844 0 60.7292 4.16078 68.2844 11.7158C75.8394 19.2706 80 29.3156 80 40C80 50.6844 75.8394 60.7294 68.2844 68.2844C60.7292 75.8394 50.6844 80 40 80ZM40 3.4375C19.8394 3.4375 3.4375 19.8394 3.4375 40C3.4375 60.1606 19.8394 76.5625 40 76.5625C60.1606 76.5625 76.5625 60.1606 76.5625 40C76.5625 19.8394 60.1606 3.4375 40 3.4375Z' fill='white'/%3E%3Cpath d='M30.7539 56.4798C30.4659 56.4798 30.1775 56.4074 29.917 56.2623C29.3727 55.9588 29.0352 55.3843 29.0352 54.761V25.5635C29.0352 24.939 29.3739 24.3635 29.9202 24.0605C30.4663 23.7576 31.1337 23.7746 31.6636 24.1052L55.1734 38.7704C55.6778 39.0849 55.9837 39.6377 55.9825 40.2323C55.9812 40.8266 55.6731 41.3782 55.1675 41.6907L31.6577 56.223C31.3811 56.394 31.0677 56.4798 30.7539 56.4798Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2007_74'%3E%3Crect width='80' height='80' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
    }
}