.c-slide-item {
    display:flex;
    justify-content: space-between;
    flex-wrap:wrap;
    &__image {
        width:55%;
        flex-basis:55%;
        padding-right:5%;
        .c-image {
            padding:16px;
            border:1px solid #fff;
            border-radius:8px;
            img {
                width:100%;
                border-radius:8px;
            }
        }
        @media (max-width: $xlc) {
            width:45%;
            flex-basis:45%;
            padding-right:16px;
        }
        @media (max-width: $lg) {
            width: 100%;
            flex-basis:100%;
            padding-right:0;
            .c-image {
                padding:8px;
                margin-bottom:24px;
            }
        }
    }
    &__content {
        width:45%;
        flex-basis:45%;
        padding-left:5%;
        transition:all .3s ease;
        h3 {
            font-family:'Gochi hand', Cursive;
            color:#00FFFF;
            font-weight:400;
            font-size:88px;
            line-height:1.09;
            margin-bottom:32px;
        }
        @media (max-width: $xlc) {
            width:55%;
            flex-basis:55%;
            padding-left:16px;
            h3 {
                font-size:56px;
            }
        }
        @media (max-width: $lg) {
            width: 100%;
            flex-basis:100%;
            padding-left:0;
            h3 {
                font-size:48px;
                margin-bottom:16px;
            }
        }
    }


}