.video-carousel-section {
    &.theme-text-white  * {
        color:#fff;
    }

    .slider-swiper-buttons {
        a {
            border:1px solid #fff;
            width: 60px;
            height: 60px;
            border-radius:30px;
            display:flex;
            justify-content: center;
            align-items: center;
            color:#fff;   
            svg {
                path {
                    fill:#fff;
                }
            }
            @media (max-width: $lg) {
                width: 40px;
                height: 40px;
            }
        }
    }
    .swiper-slide {
        a {
            display:block;
            position: relative;
            &:hover {
                &:after {
                    opacity:0.5;
                }
            }
            &:before {
                content:'';
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                z-index: 5;
                background: radial-gradient(ellipse at center,  rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%);
            }
            &:after {
                content: '';
                position: absolute;
                top:50%;
                left:50%;
                transform:translateX(-50%) translateY(-50%);
                width: 80px;
                height: 80px;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url(../images/icon-play.svg);
                background-size: 24px;
                border:1px solid #fff;
                border-radius:100px;
                display:block;
                z-index:10;
                opacity:1;
                transition:all .3s ease;
            }
        }
    }
}