.c-tile-item {
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:16px;
    border-radius:5px;
    background-color:#fff;
    @media (max-width: $lg) {
        align-items: flex-start;
    }
    @media (max-width: $sm) {
        display:block;
    }
    &__image {
        width: 400px;
        flex-basis:400px;
        @media (max-width: $lg) {
            width: 240px;
            flex-basis:240px;
        }
        @media (max-width: $sm) {
            width:100%;
        }
    }

    &__content {
        width: calc(100% - 400px);
        flex-basis: calc(100% - 400px);
        padding:0 5%;
        @media (max-width: $lg) {
            width: calc(100% - 240px);
            flex-basis: calc(100% - 240px);
        }
        @media (max-width: $sm) {
            width:100%;
            padding:16px 0 0 0;
        }
        * {
            color:#000 !important;
        }
        .c-richtext {
            h4 {
                margin-bottom:16px;
                font-weight:700;
                @media (max-width: $sm) {
                    margin-bottom:8px;
                }
            }
        }
    }
}