.c-block-item {
    .c-image {
        margin-bottom:32px;
        img {
            width:100%;
        }
        @media (max-width: $lg) {
            margin-bottom:24px;
        }
    }
    .u-h4 {
        font-size:32px;
        line-height:1.2;
        font-weight:700;
        @media (max-width: $lg) {
            font-size:24px;
        }
    }
    .subtitle {
        margin-bottom:16px;
        font-weight:700;
    }
}